
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleExpanded } from '../store/actions'
import { ITranslations } from '../store/state'
interface MenuItemMethods {
    toggleClick(): void
}

interface MenuItemComputed {
	translations: ITranslations
    levelClass: any
    className: string
}

interface MenuItemProps {
    item: IMenuItem
	
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
    name: 'MenuItem',
    props: {
		item: {},
	},
    methods: {
        toggleClick() {
            this.$store.dispatch(toggleExpanded(this.$props.item))
        }
    }, 
    computed: {
        translations() {
			return this.$store.getters.translations
		},
        levelClass() {
            return 'level-' + this.$props.item.level;
        },
        className() {
            return this.$store.getters.className;
        }
    },
	components: {
		
	}
})
