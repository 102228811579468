import { Getter } from "vuex";
import state, { IState } from './state';


const getChildrenUrl: Getter<IState, IState> = (state) => state.apiURLs.getChildren;
const menuItems: Getter<IState, IState> = (state) => state.menuItems;
const translations: Getter<IState, IState> = (state) => state.translations;
const selfTestLink: Getter<IState, IState> = (state) => state.selfTestLink;
const extraLinks: Getter<IState, IState> = (state) => state.extraLinks;
const className: Getter<IState, IState> = (state) => state.className;

export default {
	getChildrenUrl,
	menuItems,
	translations,
	selfTestLink,
	extraLinks,
	className
}
