
export interface IMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number
}
export interface IMenu {
	menuItems: IMenuItem[]
}
export interface ITranslations {
	mainMenu: string,
	openMenu: string,
	closeMenu: string
}
export interface ISelfTestLink {
	name: string,
	uri: string
}
export interface IExtraLink {
	name: string,
	uri: string
}
export interface IState {
	menuItems: IMenuItem[]
	apiURLs: {
		getChildren: string
	},
	translations: ITranslations,
	selfTestLink: ISelfTestLink,
	extraLinks: IExtraLink[],
	className: string
}

const deafultState: IState = {	
	menuItems: [],	
	apiURLs: {
		getChildren: ''
	},
	translations: {
		mainMenu: '',
		openMenu: '',
		closeMenu: ''
	},
	selfTestLink: {
		name: '',
		uri: ''
	},
	extraLinks: [],
	className: ''
}

export default deafultState;
